<nz-list class="max-w-md sm:max-w-full !mx-auto">
  <ng-container *ngFor="let service of services">
    <ng-container *ngIf="service.standalone">
      @if (itemIsInCart(service.id)) {
        <nz-list-item class="bg-primary-0 rounded-md2 mb-4 last:mb-0">
          <div class="flex items-center flex-nowrap w-full">
            <div
              class="flex-item p-2 flex-shrink-0"
              *ngIf="service.picture; &quot;cursor-pointer&quot;"
              [routerLink]="['../../details', service.id]"
            >
              <img
                src="/assets/{{ service.picture }}"
                alt="{{ service.name }}"
                class="aspect-[4/3] w-[80px] object-cover rounded-sm2"
              />
            </div>
            <div
              [ngClass]="service.picture ? 'pl-2' : ['pl-6', 'py-3']"
              class="flex-grow cursor-pointer"
              [routerLink]="['../../details', service.id]"
            >
              <h2 nz-typography>{{ service.name }}</h2>
              <div>
                {{ getTotalPriceForItem(cartItem(service.id)) | customCurrency }} &middot;
                {{ getTotalDurationForItem(cartItem(service.id)) | customTime }}
              </div>
            </div>
            <div class="flex-shrink-0">
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item [routerLink]="['../../details', service.id]">Edit</li>
                  <li nz-menu-item (click)="onDeleteItem(cartItem(service.id))">Delete</li>
                </ul>
              </nz-dropdown-menu>

              <button nz-button nzType="link" nzSize="large" nz-dropdown [nzDropdownMenu]="menu">
                <span nz-icon nzType="more" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </nz-list-item>
      } @else {
        <nz-list-item class="rounded-md2 mb-4 last:mb-0">
          <div class="grid grid-cols-1 sm:grid-cols-[45%_55%] gap-0 w-full">
            <!-- Image Container -->
            <div *ngIf="service.picture" class="p-0 sm:p-2 sm:pr-0">
              <img
                src="/assets/{{ service.picture }}"
                alt="{{ service.name }}"
                class="aspect-[4/3] object-cover sm:rounded-md2"
              />
            </div>
            <!-- Service Details -->
            <div [ngClass]="{'col-span-2': !service.picture}" class="p-6">

              <!-- service name + price -->
              <div class="flex justify-between items-start">
                  <div class="flex-1 mr-4"><h2 nz-typography >{{ service.name }}</h2></div>
                  <div class="flex-none ml-auto">
                    <h2 nz-typography >{{ getMinChoicePrice(service.id) | customCurrency }}</h2></div>
              </div>  
              <!-- description -->
              <div *ngIf="!showLongDescription[service.id] && shortServiceDescription(service)" class="mt-2">
                {{ shortServiceDescription(service) }}
                <a *ngIf="hasLongDescription(service)" nz-typography (click)="showLongDescription[service.id] = true">
                  Read more
                </a>
              </div>
              <div
                *ngIf="showLongDescription[service.id]"
                nz-typography
                nzType="secondary"
                [innerHTML]="service.description"
                class="service-description mt-2"
              ></div>
              <!-- button -->
              <div class="flex">
              <button nz-button nzType="default" class="mt-6 w-full sm:w-auto" [routerLink]="['../../details', service.id]">
                Book Service
              </button></div>
            </div>   
          </div>
        </nz-list-item>
      }
    </ng-container>
  </ng-container>
</nz-list>

@if (!cartIsEmpty()) {
  <div class="floating-button">
    <button nzBlock nz-button nzType="primary" nzSize="large" (click)="onSubmit()">Continue</button>
  </div>
}
