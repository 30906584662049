import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTime',
  standalone: true,
})
export class CustomTimePipe implements PipeTransform {
  transform(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    let result = '';

    if (hours) {
      result += hours + ' ' + (hours === 1 ? 'hr' : 'hrs');
      if (!remainingMinutes) return result;
    }

    result += ' ' + remainingMinutes + ' ' + (remainingMinutes === 1 ? 'min' : 'min'); // Keep the singular/plural for future locatization

    return result;
  }
}
