<div class="header">
  <!-- TODO: Add condition to show the business name only if there's no logo -->
  <!-- <div class="flex">
    <a href="#" target="_blank">
      <img src=".././../../assets/logo.svg" alt="logo" />
    </a>
  </div> -->
  <div class="flex">
    <h2 nz-typography>{{ account?.businessName }}</h2>
  </div>
</div>
