import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Account } from '../models/account.model';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private dataUrl: string = 'locations/details/';
  private account: Account = {} as Account;
  private uuid: string;
  accountChanged = new Subject<Account>();

  constructor(private readonly http: HttpClient) {
    // TODO take UUID from query string
    this.uuid = '700c5ebe-db11-4655-a5ec-ed7b8eb6e9a5';
  }

  get dateTimeFormat(): string {
    return 'EEEE, MMM d, ' + this.account.timeFormat;
  }

  getAccount(): Account {
    return this.account;
  }

  getCurrency(): string {
    return this.account.currency;
  }

  fetchAccount(): Observable<Account> {
    return this.http.get<Account>(this.dataUrl + this.uuid).pipe(
      tap((account) => {
        this.account = account;
        this.accountChanged.next(this.account);
      })
    );
  }
}
