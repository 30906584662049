import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateTimeUtils {
  formatTime(time: string): string {
    if (time) {
      time = time.split(':')[0] + ':' + time.split(':')[1];
    }

    return time;
  }

  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addDurationToTime(startTime: string, duration: number): string {
    if (!startTime) return startTime;
    let endTime = startTime;

    const startHour = parseInt(startTime.split(':')[0]);
    const startMinute = parseInt(startTime.split(':')[1]);
    const durationHours = Math.floor(duration / 60);
    const durationMinutes = duration % 60;
    let endHour = startHour + durationHours;
    let endMinute = startMinute + durationMinutes;
    if (endMinute >= 60) {
      endHour += 1;
      endMinute -= 60;
    }
    endTime = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;

    return endTime;
  }

  getEarliestDate(date: Date, timeSlots: string[]): Date {
    if (!timeSlots || timeSlots.length === 0) {
      return date;
    }

    date = new Date(date);
    const day = date.toISOString().split('T')[0];
    timeSlots.sort();
    return new Date(day + ' ' + timeSlots[0].substring(0, 5));
  }

  getOccupiedTimeSlots(startTime: string, interval: number, serviceDuration: number): string[] {
    let occupiedSlots: string[] = [];
    const endDate = new Date(`2000-01-01 ${this.addDurationToTime(startTime, serviceDuration)}`);
    let crtTime = startTime;

    do {
      occupiedSlots.push(crtTime);
      crtTime = this.addDurationToTime(crtTime, interval);
    } while (new Date(`2000-01-01 ${crtTime}`) < endDate);

    return occupiedSlots;
  }

  filterSlots(startTimes: string[], serviceDuration: number, interval: number): string[] {
    const minutesFromMidnight = (time: string) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };

    // Calculate the number of consecutive slots needed
    const slotsNeeded = Math.ceil(serviceDuration / interval);

    // Convert times to minutes for easier manipulation
    const timesInMinutes = startTimes.map(minutesFromMidnight);

    return startTimes.filter((time, index) => {
      if (index + slotsNeeded - 1 < timesInMinutes.length) {
        // Check if the next slotsNeeded - 1 times are consecutive
        const startMinute = minutesFromMidnight(time);
        const isValid = timesInMinutes
          .slice(index, index + slotsNeeded)
          .every((t, slotIndex) => t === startMinute + interval * slotIndex);
        return isValid;
      }
      return false;
    });
  }
}
