import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { takeUntil } from 'rxjs';

import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { AccountService } from '../../../services/account.service';
import { ServiceService } from '../../../services/service.service';
import { ServiceCategory } from '../../../models/service-category.model';
import { Account } from '../../../models/account.model';
import { HeaderComponent } from '../../header/header.component';
import { CustomCurrencyPipe } from 'src/app/pipes/custom-currency.pipe';
import { ServiceItem } from 'src/app/models/service-item.model';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { AppointmentService } from '@services/appointment.service';
import { Appointment } from 'src/app/models/appointment.model';
import { CustomTimePipe } from 'src/app/pipes/custom-time.pipe';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ThemeService } from '@services/theme.service';
import { AppointmentItem } from 'src/app/models/appointment-item.model';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';

const maxDescriptionLen = 112;

@Component({
  selector: 'app-services-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.less'],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    NzTypographyModule,
    NzGridModule,
    NzDropDownModule,
    NzFlexModule,
    NzCardModule,
    NzListModule,
    NzButtonModule,
    NzIconModule,
    HeaderComponent,
    CustomCurrencyPipe,
    CustomTimePipe,
  ],
})
export class ServicesListComponent extends SubscribedComponent implements OnInit {
  account?: Account;
  appointment?: Appointment;
  categoryId: number;
  category?: ServiceCategory;
  services: ServiceItem[] = [];
  showLongDescription: any = {};
  // count$: Observable<number>;

  constructor(
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private serviceService: ServiceService,
    private themeService: ThemeService,
    private appointmentService: AppointmentService, //private store: Store<{ counter: number }>
  ) {
    super();
    this.themeService
      .getFormSubmitAction()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((submit) => {
        if (submit) {
          this.onSubmit();
        }
      });
  }

  ngOnInit() {
    this.account = this.accountService.getAccount();
    this.appointment = this.appointmentService.getAppointment();
    this.categoryId = Number(this.route.snapshot.paramMap.get('id'));

    this.category = this.serviceService.getCategoryandServices(this.categoryId);
    this.services = this.category?.serviceItems || [];
    this.themeService.setNavTitle(this.category?.name);
    this.themeService.setCartBtnVisibility(!this.cartIsEmpty());
    this.themeService.setCartBtnLabel();
  }

  shortServiceDescription(service: ServiceItem): string {
    const strippedHtml = service.description ? service.description.replace(/<[^>]+>/g, '') : '';
    return strippedHtml.length > maxDescriptionLen
      ? strippedHtml.substring(0, maxDescriptionLen) + '...'
      : strippedHtml;
  }

  hasLongDescription(service: ServiceItem): boolean {
    const shortText = this.shortServiceDescription(service);
    return shortText.length > maxDescriptionLen;
  }

  cartIsEmpty() {
    return this.appointmentService.cartIsEmpty();
  }

  cartItem(serviceItemId: number): AppointmentItem | null {
    return this.appointmentService.getItem(serviceItemId) || null;
  }

  itemIsInCart(serviceItemId: number): boolean {
    return !!this.cartItem(serviceItemId);
  }

  getTotalDurationForItem(item: AppointmentItem): number {
    return this.appointmentService.getTotalDurationForItem(item);
  }

  getTotalPriceForItem(item: AppointmentItem): number {
    return this.appointmentService.getTotalPriceForItem(item);
  }

  getMinChoicePrice(id: number): number {
    return this.serviceService.getMinChoice(id).price;
  }

  async onDeleteItem(item: AppointmentItem) {
    await this.appointmentService.deleteItem(item);
    this.ref.markForCheck();
  }

  onSubmit() {
    this.router.navigate(['services', 'select-date']);
  }

  // increment() {
  //   this.store.dispatch(increment({ amount: 2 }));
  // }

  // decrement() {
  //   this.store.dispatch(decrement({ amount: 2 }));
  // }
}
